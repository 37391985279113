export const IsEmptyObj = (obj) => (obj !== undefined && obj !== null) && Object.keys(obj).length === 0 ? true : false;
export const RemoveEmptyPropFromObj = (obj) => !IsEmptyObj(obj) && Object.fromEntries(Object.keys(obj).filter(k => obj[k] !== "").map(k => [k, obj[k]]));
export const BackToTop = () => window.scrollTo({
  top: 0,
  behavior: 'smooth'
})

export const webShare = (title, url = '') => {
  if (navigator.share) {
    navigator.share({
      title: title,
      url: url,
    }).then(() => {
      return "Success";
    }).catch(console.error);
  }
}