import React from 'react';

import './Input.scss';

export default function Input(props) {
  const {
    value,
    name,
    placeholder,
    className,
    onChange,
    type,
    error,
    multiline = false,
    ...other } = props;

  const attr = {
    placeholder: placeholder,
    type: type,
    name: name,
    value: value,
    onChange: onChange,
    className: className + (error ? ' error-border' : ' def-border'),
    ...other
  }

  return (
    <div className='input-control'>
      {multiline ? <textarea {...attr} /> : <input {...attr}/>}
      {error && <span>{error}</span>}
    </div>
  )
}
