import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Service.scss";
import { AppWrap, MotionWrap } from "../../wrapper";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices([
      {
        title: "I build my client's project strongly and securely.",
        description:
          "I never share client and project info. I always write code using secure patterns & latest technology.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/security.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "I'm Expert On .NET Core",
        description:
          "I'm working on the dotnet framework for more than 5 years. I have built lots of big projects using dotnet. Like: video, massage & dating apps.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/dotnet-cover.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "I'm Expert on ReactJS/ React Native",
        description:
          "I'm working on ReactJS for more than 3 years. For most of my web app projects, I used ReactJS. Also, I love RactJS❤️.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/react-native.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "I'm Expert on MySQL Server",
        description:
          "I'm using MySQL more than 4 years. I love to use MySQL. Because it's open-source and It's a powerful database management system.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/mysql.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "I'm Intermediate on Blazor",
        description:
          "I'm working on Blazor more than 2 years. I have completed lots of my client's blazor requirements. Lots of entrepreneurs love to use Blazor on his/her project.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/blazor.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "Frontend designing library and framework.",
        description:
          "I love to provide luxurious look on clients projects. That's why I use the latest UI(User Interface) library and framework. Material-UI❤️ is my favorite library.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/frontend-tech.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "I'm expert on MSSQL Server",
        description:
          "I'm using MSSQL Server more than 4 years. I have built lots of WinForm/Windows applications using the MSSQL Server. Like: Shop, Hospital & pharmacy management systems.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/mssql.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
      {
        title: "DevOps Tools",
        description:
          "I use these tools for my development. I'm expert on GitHub, Git, Azure Dev & Git Action. I love to use both GitHub & Azure Dev.",
        img: `${process.env.PUBLIC_URL}/assets/img/services/DevOps.png`,
        link: "https://www.fiverr.com/jihadhassan280/desktop-and-web-application-in-c-sharp",
      },
    ]);
  }, []);

  return (
    <>
      <h2 className="head-text">
        I Know that <span>Good Development/App</span> <br />
        means <span>Good Business</span> 🎉
      </h2>
      <div className="app__profiles container">
        {services.map((serv, i) => (
          <motion.div
            key={i}
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
          >
            <img src={serv.img} alt={serv.title} />
            <h2
              className="bold-text"
              style={{ marginTop: 20, fontSize: "1.1rem" }}
            >
              {serv.title}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {serv.description}
            </p>
          </motion.div>
        ))}
      </div>

      {/* <button className='btn-primary icon-btn mt-40'>
        Show More <BsCaretDown />
      </button> */}
    </>
  );
};

export default AppWrap(
  MotionWrap(Services, "app__about"),
  "services",
  "app__whitebg"
);
