import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "./component";
import {
  About,
  Header,
  Skills,
  Testimonial,
  Work,
  Contact,
  ViewPost,
} from "./container";

import "./App.scss";

export const AppContext = createContext(null);

const App = () => {
  const [themeMode, setThemeMode] = useState("dark");

  const handleColor = () =>
    setThemeMode((curr) => (curr === "light" ? "dark" : "light"));

  useEffect(() => {
    // Check to see if Media-Queries are supported
    if (window.matchMedia) {
      // Check if the dark-mode Media-Query matches
      if (window.matchMedia("(prefers-color-scheme: dark)").matches)
        setThemeMode("dark");
      else setThemeMode("light");
    } else setThemeMode("dark");
    return () => {
      setThemeMode("light");
    };
  }, []);

  return (
    <AppContext.Provider value={{ themeMode, handleColor }}>
      <div className="app" id={themeMode}>
        <Router>
          <Routes>
            <Route path="/view_post/:title/:id" element={<ViewPost />} />
            <Route
              index
              element={
                <>
                  <Navbar />
                  <Header />
                  <About />
                  <Work />
                  <Skills />
                  <Testimonial />
                  <Contact />
                </>
              }
            />
            <Route path="*" element={<h1>404 - Page Not Found</h1>} />
          </Routes>
        </Router>
      </div>
    </AppContext.Provider>
  );
};

export default App;
