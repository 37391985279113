import React, { useState } from 'react'
import { Form } from '../../../layout';
import { Input } from '../../../controls';
import { Model, UseForm } from '../../../hooks';
import { FiSend } from 'react-icons/fi';
import { ErrorCard } from '../../index';
import './ContactForm.scss';

const ContactForm = () => {

  const { ContactMdl } = Model();
  const { values, errors, setErrors, handleInpChg } = UseForm(ContactMdl);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setIsFormSubmitted(true);
    }
  };

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName === "" ? "Name is required" : ""
    temp.email = values.email === "" ? "email is required" : ((/^$|.+@.+..+/).test(values.email) ? "" : "Invalid Email")
    temp.msg = values.msg === "" ? "Message is required" : ""

    setErrors({ ...temp });
    return Object.values(temp).every(x => x === "");
  }

  return (
    <>
      {!isFormSubmitted ? (
        <>
          <div className='title-box'>
            <h2 className="sub-head-text">Send Message</h2>
          </div>
          <div className="app__contact-form app__flex">
            <ErrorCard {...{ errors }} />
            <Form onSubmit={handleSubmit}>
              <div className='app__flex'>
                <Input
                  placeholder="Your Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInpChg}
                  error={errors.fullName}
                />
                <Input
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  error={errors.email}
                  onChange={handleInpChg}
                />
              </div>
              <div>
                <Input multiline={true}
                  placeholder="Your Message"
                  value={values.msg}
                  error={errors.msg}
                  name="msg"
                  onChange={handleInpChg} />
              </div>
              <div className='btn-div'>
                <button type="submit" className="btn-primary icon-btn">
                  {!loading ? 'Send Message' : 'Sending...'}<FiSend />
                </button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )
      }
    </>
  )
}

export default ContactForm;