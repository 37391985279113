import { useState } from "react"

export default function UseForm(getFreshMdlObj) {
  const [values, setValues] = useState(getFreshMdlObj);
  const [errors, setErrors] = useState({});

  const handleInpChg = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    })
    value !== "" && setErrors({ ...errors, [name]: "" })
  }

  return {
    handleInpChg,
    values,
    setValues,
    errors,
    setErrors
  }
}
