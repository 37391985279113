import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { AnimatePresence, motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Testimonial.scss';


const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 500 : -500,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 500 : -500,
      opacity: 0
    };
  }
};

const Testimonial = () => {
  const [[currentIndex, direction], setCurrentIndex] = useState([0, 0]);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setTestimonials([
      {
        feedback: 'Great communication and work, very happy with the quality, communication, and work output. Highly recommend.',
        name: 'Sid',
        company: 'DramLedger.',
        img: `${process.env.PUBLIC_URL}/assets/img/client/sid.png`,
      },
      {
        feedback: 'Did an amazing job. A true ASP.NET expert. The experience was great . Thank you for everything.',
        name: 'Ali Salman',
        company: '',
        img: `${process.env.PUBLIC_URL}/assets/img/client/alisalman.png`,
      },
      {
        feedback: 'We have completed an amazing job. Your a Great React Developer. Thanks, Jihad for every.',
        name: 'Emma',
        img: '',
      },
    ]);

  }, []);


  const paginate = (newInd) => {
    newInd === -1 ?
      setCurrentIndex([currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1, newInd]) :
      setCurrentIndex([currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1], newInd);
  };

  return (
    <>
      <h1 className="head-text"><span>Client</span> Said 🧏‍♀️🧏‍♂️</h1>
      {testimonials.length && (
        <AnimatePresence initial={false} custom={direction}>
          <div>
            <motion.div
              key={currentIndex}
              variants={variants}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 600, damping: 35 },
                opacity: { duration: 1 }
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate();
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate();
                }
              }}
            >
              <div className="app__testimonial-item app__flex">
                <img src={testimonials[currentIndex].img !== '' ? testimonials[currentIndex].img : `${process.env.PUBLIC_URL}/assets/img/noimg.png`}
                  alt={testimonials[currentIndex].name} />
                <div className="app__testimonial-content">
                  <p className="p-text">{testimonials[currentIndex].feedback}</p>
                  <div>
                    <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                    <h5 className="p-text">{testimonials[currentIndex].company}</h5>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </AnimatePresence>

      )}
      <div className="app__testimonial-btns app__flex">
        <button className="app__flex" onClick={() => paginate(1)}>
          <HiChevronLeft />
        </button>

        <button className="app__flex" onClick={() => paginate(-1)}>
          <HiChevronRight />
        </button>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__primarybg',
);
