import React from 'react'

const Form = ({children, ...other}) => {
  return (
    <form {...other}>
      {children}
    </form>
  )
}

export default Form