export default function Model() {

  const ContactMdl = () => ({
    id: 0,
    fullName: '',
    email: '',
    msg: '',
    sentDate: '',
  })

  return {
    ContactMdl
  }
}
