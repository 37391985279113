import React, { useContext } from "react";
import "./Toggle.scss";
import { BsMoon, BsSun } from 'react-icons/bs';
import { AppContext } from "../../App";


const Toggle = () => {
  const { themeMode, handleColor } = useContext(AppContext);

  return (
    <div className="toggle-comp">
      <div className="toggle" onClick={() => handleColor()}>
        <BsMoon />
        <BsSun />
        <div
          className="t-button"
          style={themeMode === 'dark' ? { left: "2px" } : { right: "2px" }}
        ></div>
      </div>
    </div>

  );
};

export default Toggle;
