import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Contact.scss';
import ContactForm from './ContactForm/ContactForm';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';

const Contact = () => {
  const [contacts, setContacts] = useState([])
  useEffect(() => {
    setContacts([
      {
        id: 1,
        contact: 'jihadhassan280',
        contactOn: 'Contact on Fiverr',
        img: `${process.env.PUBLIC_URL}/assets/img/contact/fiverr.png`,
        link: 'https://www.fiverr.com/jihadhassan280',
      },
      {
        id: 2,
        contact: '+880 1614-021833',
        contactOn: 'Contact on Whatsapp',
        img: `${process.env.PUBLIC_URL}/assets/img/contact/whatsapp.png`,
        link: 'https://wa.me/01614021833',
      },
      {
        id: 3,
        contact: 'jihadhassan',
        contactOn: 'Contact on LinkedIn',
        img: `${process.env.PUBLIC_URL}/assets/img/contact/linkedin.png`,
        link: 'https://www.linkedin.com/in/jihadhassan',
      },
      {
        id: 4,
        contact: 'jihadhassan280',
        contactOn: 'Contact on Facebook',
        img: `${process.env.PUBLIC_URL}/assets/img/contact/facebook.png`,
        link: 'https://www.facebook.com/jihadhassan280',
      },
    ])

    return () => {

    }
  }, [])


  return (
    <>
      <h1 className='head-text'>Contact <span>Me 📲</span></h1>
      <div className='dotted-border' />
      <div className='title-box'>
        <h2 className="sub-head-text">Contact By</h2>
      </div>
      <div className="app__contact-cards">
        {contacts.map((item, i) =>
          <a href={item.link} key={i} target='_blank' rel="noreferrer" >
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__contact-card"
              style={{ backgroundColor: 'var(--primaryTrans-color)' }}
            >
              <img src={item.img} alt="phone" />
              <div className="text-box">
                <h3 style={{ color: 'var(--primary-color)' }}>{item.contact}</h3>
                <span style={{ color: 'var(--gray-color)' }}>{item.contactOn}</span>
              </div>
            </motion.div>
          </a>
        )}
      </div>
      <ContactForm />
    </>
  );

};

export default AppWrap(
  MotionWrap(Contact, 'app__contact'),
  'contact',
  'app__whitebg',
);
