import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Skills.scss';

const Skills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    setSkills([
      {
        name: '.NET Core Web API',
        exp: 'Expert',
        expDur: '4 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/dotnet.png`,
        type: 1
      },
      {
        name: 'ASP.NET Core MVC',
        exp: 'Expert',
        expDur: '4 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/dotnetmvc.png`,
        type: 1
      },
      {
        name: 'ASP.NET CORE',
        exp: 'Expert',
        expDur: '5 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/asp.net.png`,
        type: 1
      },
      {
        name: 'ReactJS',
        exp: 'Expert',
        expDur: '4 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/react.png`,
        type: 2
      },
      {
        name: 'React Native',
        exp: 'Expert',
        expDur: '2 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/react-native.png`,
        type: 2
      },
      {
        name: '.NET Blazor',
        exp: 'Expert',
        expDur: '3 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/blazor-icon.png`,
        type: 2
      },
      {
        name: 'MUI (Material UI)',
        exp: 'Expert',
        expDur: '3 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/mui.png`,
        type: 3
      },
      {
        name: 'Bootstrap',
        exp: 'Expert',
        expDur: '4 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/bootstrap.png`,
        type: 3
      },
      {
        name: 'Tailwind CSS',
        exp: 'Expert',
        expDur: '2 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/tailwindcss.png`,
        type: 3
      },
      {
        name: 'C#',
        exp: 'Expert',
        expDur: '5 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/c-sharp.png`,
        type: 4
      },
      {
        name: 'JavaScript',
        exp: 'Expert',
        expDur: '5 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/javascript.png`,
        type: 4
      },
      {
        name: 'Python',
        exp: 'Intermediate',
        expDur: '2 YEAR EXPERIENCE',
        icon: `${process.env.PUBLIC_URL}/assets/img/tech/python.png`,
        type: 4
      },
      
    ])

  }, []);


  return (
    <>
      <h2 className="head-text">Skills <span>&</span> Experiences 👨‍💻</h2>
      <div className='dotted-border' />

      <div className='group-title-box'>
        <h2 className='group-title'>Programming Languages</h2>
      </div>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.filter(j => j.type === 4).map((skill, i) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={`${i}j`}
            >
              <div className="skills-card">
                <img src={skill.icon} alt={skill.name} />
                <div className='skills-text-box'>
                  <p>{skill.name}</p>
                  <span className='exp-label'>{skill.exp}</span>
                  <span className='exp-label'>{skill.expDur}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <div className='group-title-box'>
        <h2 className='group-title'>BackEnd Framework</h2>
      </div>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.filter(j => j.type === 1).map((skill, i) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={`${i}j`}
            >
              <div className="skills-card">
                <img src={skill.icon} alt={skill.name} />
                <div className='skills-text-box'>
                  <p>{skill.name}</p>
                  <span className='exp-label'>{skill.exp}</span>
                  <span className='exp-label'>{skill.expDur}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <div className='group-title-box'>
        <h2 className='group-title'>FrontEnd & Mobile Framework</h2>
      </div>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.filter(j => j.type === 2).map((skill, i) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={`${i}j`}
            >
              <div className="skills-card">
                <img src={skill.icon} alt={skill.name} />
                <div className='skills-text-box'>
                  <p>{skill.name}</p>
                  <span className='exp-label'>{skill.exp}</span>
                  <span className='exp-label'>{skill.expDur}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <div className='group-title-box'>
        <h2 className='group-title'>UI(User Interface) Framework/Library</h2>
      </div>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.filter(j => j.type === 3).map((skill, i) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={`${i}j`}
            >
              <div className="skills-card">
                <img src={skill.icon} alt={skill.name} />
                <div className='skills-text-box'>
                  <p>{skill.name}</p>
                  <span className='exp-label'>{skill.exp}</span>
                  <span className='exp-label'>{skill.expDur}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
