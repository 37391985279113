import React from 'react'
import { RemoveEmptyPropFromObj } from '../../hooks/Method'
import './ErrorCard.scss';

const ErrorCard = ({ errors = {} }) => {
  errors = RemoveEmptyPropFromObj(errors);
  let errLength = Object.values(errors).length;

  return (
    <div className={`error-card ${errLength > 0 ? 'show' : ''}`}>
      {errLength > 0 && <>
        <h6 className='error-title'>Errors: </h6>
        <ul>
          {Object.values(errors).map((err, i) => (
            <li key={i}>{err}</li>
          ))}
        </ul>
      </>}
    </div>
  )
}

export default ErrorCard