import React from 'react';
import { useParams } from 'react-router-dom'
import { BackToTop, webShare } from '../../hooks/Method';
import { FaShareSquare } from 'react-icons/fa';
import './ViewPost.scss';

const ViewPost = () => {
  const { pgType, id } = useParams();
  BackToTop();
  console.log('param: ', pgType, id);

  const handleShare = (title) => {
    webShare(title);
  }

  return (
    <div className='view-post'>
      <div className='page-card'>
        <h1>Jihad Hassan is a Good Programmer.</h1>
        <div className='page-content'>
        </div>
        <div className='btn-box'>
          <button onClick={() => handleShare('Jihad Hassan is a Good Programmer')} className='btn-primary'> Share <FaShareSquare /></button>
        </div>
      </div>
    </div>
  )
}

export default ViewPost