import React from 'react';
import ListData from '../hooks/ListData';

const NavigationDots = ({ active }) => {
  const { menu } = ListData();
  return (
    <div className="app__navigation">
      {menu.map((item, index) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`#${item.name}`}
          key={item.name + index}
          className={`app__navigation-dot ${active === item.name ? 'nav-dot-active' : ''}`}
        />
      ))}
    </div>
  )
}

export default NavigationDots;