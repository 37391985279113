import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
// import { BsCaretDown } from 'react-icons/bs';
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Work.scss";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const defData = [
      {
        imgUrl: `${process.env.PUBLIC_URL}/assets/img/portfolio/strata-management.png`,
        name: "HOA",
        projectLink: "https://sm876.com/",
        codeLink: "",
        title: "HOA Management & SASS",
        description:
          "Developed using .NET 6 & ReactJS + Redux. And This a PWA WebApp.",
        tags: [
          "PWA & .NET + ReactJS",
          "WebApp",
          "ReactJS",
          ".NET",
          "Material-UI",
        ],
        classes: "obj-contain",
      },
      {
        imgUrl: `${process.env.PUBLIC_URL}/assets/img/portfolio/POS.png`,
        name: "POS",
        projectLink: "https://pos_demo.jhprog.com/",
        codeLink: "",
        title: "POS (Point of Sale) System",
        description:
          "Developed using .NET 6 & ReactJS + Redux. And This a PWA WebApp.",
        tags: [
          "PWA & .NET + ReactJS",
          "WebApp",
          "ReactJS",
          ".NET",
          "Material-UI",
        ],
        classes: "obj-contain",
      },
      {
        imgUrl: `${process.env.PUBLIC_URL}/assets/img/portfolio/pos-logo.png`,
        name: "POS-Landing",
        projectLink: "https://jhpos.jhprog.com/",
        codeLink: "",
        title: "POS Landing Page",
        description:
          "Developed using .NET 6 & ReactJS + Redux. And This a PWA WebApp.",
        tags: [
          "PWA & .NET + ReactJS",
          "WebApp",
          "ReactJS",
          ".NET",
          "Material-UI",
        ],
        classes: "obj-contain",
      },
      // {
      //   imgUrl: `${process.env.PUBLIC_URL}/assets/img/portfolio/alumni.png`,
      //   name: "Alumni",
      //   projectLink: "https://ieb-alumni.org/",
      //   codeLink: "",
      //   title: "IEB(Career Mngmt. WebApp)",
      //   description:
      //     "Developed using .NET 6 & ReactJS + Redux. And This a PWA WebApp.",
      //   tags: [
      //     "PWA & .NET + ReactJS",
      //     "WebApp",
      //     "ReactJS",
      //     ".NET",
      //     "Material-UI",
      //   ],
      //   classes: "obj-cover",
      // },
      // {
      //   imgUrl: `${process.env.PUBLIC_URL}/assets/img/portfolio/technomart.png`,
      //   name: "technomart",
      //   projectLink: "http://technomart.com.bd/",
      //   codeLink: "",
      //   title: "TechnoMart (E-Commerce)",
      //   description:
      //     "Developed using Asp.Net Core. This a Multi-Vendor E-Commerce.",
      //   tags: [".NET", "Web App", "Material-UI"],
      //   classes: "obj-contain",
      // },
    ];
    setWorks(defData);
    setFilterWork(defData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        My Creative <span>Portfolio</span> Section 🗃️
      </h2>
      <div className="dotted-border" />
      <div className="app__work-filter">
        {[".NET", "PWA APP", "ReactJS", "Blazor", "WebApp", "All"].map(
          (item, index) => (
            <button
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item}
            </button>
          )
        )}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio container"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={work.imgUrl} alt={work.name} className={work.classes} />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                {work.codeLink !== "" && (
                  <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillGithub />
                    </motion.div>
                  </a>
                )}
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>

      {/* <button className='btn-primary icon-btn mt-40'>
        Show More <BsCaretDown />
      </button> */}
    </>
  );
};

export default AppWrap(MotionWrap(Work, "app__works"), "work", "");
