
export default function listData() {
  const menu = [
    {id: 1, name: 'home'},
    {id: 2, name: 'services'},
    {id: 3, name: 'work'},
    {id: 4, name: 'skills'},
    {id: 5, name: 'testimonial'},
    {id: 6, name: 'contact'},
  ]
  return {
    menu,
  }
}
