import React, { useState } from 'react'
import {
  AiOutlineHome,
  AiFillHome,
} from 'react-icons/ai';
import { MdWorkOutline, MdWork } from 'react-icons/md';
import {
  RiUserStarLine,
  RiUserStarFill,
  RiServiceLine,
  RiServiceFill,
  RiContactsBookLine,
  RiContactsBookFill
} from 'react-icons/ri';
import { IoSettingsOutline, IoSettingsSharp } from 'react-icons/io5';
import ListData from '../../hooks/ListData';
import './Navbar.scss';


const Navbar = () => {
  const { menu } = ListData();
  const [active, setActive] = useState('home');
  const handleActive = (val) => setActive(val);

  const menuIcon = (val) => {
    switch (val) {
      case 'services':
        return (
          active === val ? <IoSettingsSharp /> : <IoSettingsOutline />
        )
      case 'work':
        return (
          active === val ? < MdWork /> : <MdWorkOutline />
        )
      case 'skills':
        return (
          active === val ? < RiUserStarFill /> : <RiUserStarLine />
        )
      case 'testimonial':
        return (
          active === val ? < RiServiceFill /> : <RiServiceLine />
        )
      case 'contact':
        return (
          active === val ? < RiContactsBookFill /> : <RiContactsBookLine />
        )
      default:
        return (
          active === val ? <AiFillHome /> : <AiOutlineHome />
        )
    }
  }

  return (
    <div className='navbar'>
      <div>
        {menu.map((item, i) => (
          <a key={i} href={`/#${item.name}`}
            onClick={() => handleActive(item.name)}
            className={active === item.name ? 'active' : ''}>
            {menuIcon(item.name)}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Navbar